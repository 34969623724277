import styled from "styled-components";

export const ResumeItemContainer = styled.div`
    padding:1rem;
    border-radius: 0.5rem;
    margin:1rem auto;
    background-color: rgba(100,103,108,0.8);
    box-shadow: inset 0 0 10px rgba(150,153,158,0.7);
    cursor:pointer;
    width:80%;
    @media only screen and (max-width: 780px){
        width:80%;
    }
    
`