import { getArrayofYears } from "../../common/utils";
import {
  TimeLineContainer,
  TimeLineBubbleDiv,
  TimeLineBar,
  ActiveTimeLineBar,
  ActiveTimeLineBubbleDiv,
} from "./ResumeTimeline.styles";

type TimeLineProps = {
  low:number;
  activeYears?:number[];
};
const ResumeTimeLine = (props: TimeLineProps) => {
  const {low, activeYears} = props;
  const currentYear = new Date().getFullYear();
  const arrayOfYears = getArrayofYears(low,currentYear);

  return (
    <TimeLineContainer>
      {arrayOfYears.map((item, i) => {

        if(i<arrayOfYears.length - 1)
        {
          if(activeYears && activeYears.length>1)
          {
            if(activeYears.includes(item))
            {
              if(activeYears[0] === item)
              {
                return(<TimelineBubble year={item} start={true} bar={true} key={item}  multipleYears={true} active={true}/>);
              }
              return(<TimelineBubble year={item} bar={true} key={item} multipleYears={true} active={true}/>);
            }
            else{
              return(<TimelineBubble year={item} bar={true} key={item} multipleYears={true}/>);
            }
          }
          else if(activeYears?.includes(item)){
            return(<TimelineBubble year={item} bar={true} key={item} active={true}/>);
          }
          else{
            return(<TimelineBubble year={item} bar={true} key={item}/>);
          }
        }
        else{
          if(activeYears?.includes(item))
          {
            return(<TimelineBubble year={item} key={item} active={true}/>);
          }
          else{
            return(<TimelineBubble year={item} key={item} />);
          }
          
        }
      })}
    </TimeLineContainer>
  );
};
export default ResumeTimeLine;

type TimeLineBubbleProps = {
  bar?: boolean;
  active?: boolean;
  year: number;
  multipleYears?: boolean;
  start?:boolean;
};
const TimelineBubble = (props: TimeLineBubbleProps) => {
  return props.bar ? (
    props.active ? (
      props.multipleYears ? 
      props.start?
      (
        <>
          <ActiveTimeLineBubbleDiv>{props.year}</ActiveTimeLineBubbleDiv>
          <TimeLineBar></TimeLineBar>
        </>
      )
      :
      (
        <>
          <ActiveTimeLineBubbleDiv>{props.year}</ActiveTimeLineBubbleDiv>
          <ActiveTimeLineBar></ActiveTimeLineBar>
        </>
      ) : (
        <>
          <ActiveTimeLineBubbleDiv>{props.year}</ActiveTimeLineBubbleDiv>
          <TimeLineBar></TimeLineBar>
        </>
      )
    ) : (
      <>
        <TimeLineBubbleDiv>{props.year}</TimeLineBubbleDiv>
        <TimeLineBar></TimeLineBar>
      </>
    )
  ) : props.active ? (
    <ActiveTimeLineBubbleDiv>{props.year}</ActiveTimeLineBubbleDiv>
  ) : (
    <TimeLineBubbleDiv>{props.year}</TimeLineBubbleDiv>
  );
};
