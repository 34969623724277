import ProjectItem from '../../components/ProjectItem/ProjectItem';

const Projects = () => {
    return(
        <div>
            <div style={{textAlign:"center", color:"white", fontSize:"clamp(2rem,3vw,3rem"}}>Projects</div>
        <div style={{display:"flex"}}>
            <ProjectItem 
            title='To do List' 
            description='To do list application built with React, Next.js and Firebase, written in Typescript.'
            extendedDescription='The application utilizes Firebases user authentication system, and Cloud Firestore, to provide a user friendly todo list with a dashboard to show high priority actions, upcoming deadlines and items past deadline'
            techIcons={["react", "firebase", "nextjs"]}
            languages={["typescript"]}
            gitHubLink='https://github.com/MobsofFools/to-do-app'
            hostedLink='https://portfolio-todo-2022.web.app/'
            />
        </div>
        </div>
    );
}
export default Projects;