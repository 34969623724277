type NavRoute = {
    path:string,
    name:string,
}
export const routes:NavRoute[] = [
    {path:'/',name:'Home'},
    {path:'projects', name:'Projects'},
    {path:'resume', name: 'Work Experience'},
    {path:'education', name:'Education'}, 
    {path:'coverletter', name:'PlaceHolder'},
    {path:'contactme', name:'Contact Me'},
]