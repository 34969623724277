import { useState, useEffect } from "react";
import { ThemeColorProps, ThemeContextProps } from "./common/types";
import { ThemeContext } from "./common/context";
import { LightTheme, Theme } from "./common/theme";
import { getWithExpiry, setWithExpiry } from "./common/utils";
//Components
import NavBar from "./components/NavBar/NavBar";
import Router from "./components/Router/Router";
import StickyCornerFooter from "./components/StickyCornerFooter/StickyCornerFooter";

 
const App = () => {
const [theme, setTheme] = useState<ThemeColorProps>(Theme);

const themeContextProvider:ThemeContextProps = {
  theme:theme,
  setTheme:setTheme,
}

useEffect(()=> {
    if(getWithExpiry("active_theme"))
    {
      const theme =  getWithExpiry("active_theme")
      switch (theme) {
        case 'dark': 
        {
          setTheme(Theme);
          break;
        }
        case 'light':
        {
          setTheme(LightTheme);
          break;
        }
        default:
          break;
      }
    }
    else{
      setWithExpiry("active_theme","dark",3600000);
      setTheme(Theme);
    }
},[])
  return (
    <ThemeContext.Provider value={themeContextProvider}>
      <NavBar />
      <StickyCornerFooter/>
      <Router/>
    </ThemeContext.Provider>
  );
};

export default App;
