import { createContext, useContext } from "react";
import { ThemeContextProps } from "./types";

export const ThemeContext = createContext<ThemeContextProps|null>(null);
export const useTheme = () => {
    let context = useContext(ThemeContext);
    if(context === undefined)
    {
        throw Error();
    }
    return {...context};
}
