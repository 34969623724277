import styled from "styled-components";

export const TimeLineContainer = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
    width:100%;
    text-align:center;
    padding:2rem;
    overflow-y:scroll;
    ::-webkit-scrollbar {
        display:none;
    }
`
export const TimeLineBubbleDiv = styled.div`
    border-radius:50%;
    height:3rem;
    width:3rem;
    line-height:3rem;
    background-color: rgba(100,100,100,0.3);
    z-index:1;
    cursor:pointer;
    box-shadow: 0 0 3px 3px rgba(200,200,200,0.5);

`
export const TimeLineBar = styled.div`
    width:0.5rem;
    background-color: rgba(100,100,100,0.5);
    height:clamp(3rem,10vh,15rem);
    margin-top:-1px;
    margin-bottom:-1px;
`
export const ActiveTimeLineBubbleDiv = styled.div`
    border-radius:50%;
    height:3rem;
    width:3rem;
    line-height:3rem;
    background-color: rgba(180,180,180,0.5);
    z-index:1;
    cursor:pointer;
    box-shadow: 0 0 6px 3px white;
    animation: pulse-white 2s infinite;

    @keyframes pulse-white {
        0% {
          transform: scale(1);
          box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
        }
        
        70% {
          transform: scale(1.05);
          box-shadow: 0 0 0 15px rgba(255, 255, 255, 0);
        }
        
        100% {
          transform: scale(1);
          box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
        }
      }
      

`
export const ActiveTimeLineBar = styled.div`
    width:0.5rem;
    background-color: rgba(180,180,180,0.5);
    height:10vh;
    margin-top:-1px;
    margin-bottom:-1px;
`