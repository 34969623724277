import { ProjectItemContainer } from "./ProjectItem.styles";
import { ITechIcon, ILanguage } from "../../common/types";
import TechIconDisplay from "./TechIconDisplay/TechIconDisplay";
import LanguageDisplay from "./LanguageDisplay/LanguageDisplay";
import Github from "../../img/png/GitHub-Mark-Light-120px-plus.png";

type IProjectItem = {
  title: string;
  description: string;
  extendedDescription?: string;
  techIcons?: ITechIcon[] | undefined;
  languages?: ILanguage[] | undefined;
  hostedLink?: string;
  gitHubLink?: string;
};

const ProjectItem = (props: IProjectItem) => {
  const {
    title,
    description,
    techIcons,
    languages,
    extendedDescription,
    hostedLink,
    gitHubLink,
  } = props;
  return (
    <ProjectItemContainer>
      <div style={{ padding: "1rem" }}>
        <div style={{ textAlign: "center", fontSize: "1.5rem" }}>{title}</div>
        <br />
        <div style={{ width: "40ch" }}>
          {description}
          <br />
          {extendedDescription}
        </div>
        <br />
        <div>Built With:</div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "1rem",
          }}
        >
          <LanguageDisplay icons={languages} />
          <TechIconDisplay icons={techIcons} />
        </div>
        <br />
        {gitHubLink || hostedLink ? <div>Available:</div> : null}
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-around",
            alignItems: "center",
            marginTop: "1rem",
          }}
        >
          {gitHubLink ? (
            <a href={gitHubLink} target="_blank" rel="noreferrer">
              <img
                src={Github}
                style={{ height: "3.5rem", width: "auto", margin: "0 0.25rem" }}
                alt="git"
              ></img>
            </a>
          ) : null}
          {hostedLink ? (
            <a
              href={hostedLink}
              target="_blank"
              rel="noreferrer"
              style={{
                height: "3.5rem",
                width: "3.5rem",
                backgroundColor: "white",
                borderRadius: "50%",
                color: "black",
                fontSize: "11px",
              }}
            >
              <div
                style={{
                  display: "grid",
                  placeItems: "center",
                  alignItems: "center",
                  height: "3.5rem",
                  textAlign: "center",
                  margin: "0 0.25rem",
                }}
              >
                Hosted <br /> Here
              </div>
            </a>
          ) : null}
        </div>
      </div>
    </ProjectItemContainer>
  );
};
export default ProjectItem;
