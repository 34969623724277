import styled from "styled-components";

export const HomePageHeaderContainer = styled.div`
    font-size: 3rem;
    text-align:center;
    height:clamp(20vh,30vh,1000px);
    display:flex;
    justify-content:center;
    align-items:center;
    position:sticky;

`
export const HomePageContainer = styled.div`
    padding:3rem;
    color:white;
`
export const AboutMeContainer = styled.div`
p{
    font-size:1.25rem;
}
    
`
export const SkillsContainer = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    flex-wrap:wrap;
    background-color: rgba(100,100,100,0.3);
    box-shadow: 0 0 3px 3px rgba(200,200,200,0.5);
    svg{
        height:4rem;
        width:4rem;
        margin:0 0.5rem;
    }
`;
export const FlexContainer = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;
    flex-wrap:wrap;
    p{
        font-size:1.25rem;
    }
`