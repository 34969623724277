type HamburgerMenuProps = {
  color?:string;
  size?:number;
}
const HamburgerMenu = (props:HamburgerMenuProps) => {
  const size = props.size? props.size : 100;
  const viewBox = `0 0 ${size} ${size}`;
  const width =props.size? props.size*0.4 : 40;
  const height =props.size? props.size*0.4 : 40;
  const rectW = props.size? props.size : 100;
  const rectH = props.size? props.size*0.2 : 20;
  const rectrx = props.size? props.size*0.08 : 8;
  const recty1 = props.size? props.size*0.3 : 30;
  const recty2 = props.size? props.size*0.6 : 60;

  return (
    <svg viewBox={viewBox} width={width} height={height} fill={props.color}>
      <rect width={rectW} height={rectH} rx={rectrx}></rect>
      <rect y={recty1} width={rectW} height={rectH} rx={rectrx}></rect>
      <rect y={recty2} width={rectW} height={rectH} rx={rectrx}></rect>
    </svg>
  );
};
export default HamburgerMenu