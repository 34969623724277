import styled from "styled-components";

export const DesktopHomeContainer = styled.div`
  padding-left: clamp(5rem, 20vw, 16rem);
  min-height: 100vh;
  background-color: rgb(54, 57, 63);
  /* background:radial-gradient(circle at 100%, rgb(10, 13, 16), rgb(34, 37, 43), rgb(50, 69, 80), rgb(75, 68, 60), rgb(54, 57, 63), rgb(54, 80, 63));
  background-size: 800% 800%;

  -webkit-animation: BackgroundScroll 100s ease infinite;
  -moz-animation: BackgroundScroll 100s ease infinite;
  animation: BackgroundScroll 100s ease infinite;
  @-webkit-keyframes BackgroundScroll {
    0% {
      background-position: 0% 14%;
    }
    50% {
      background-position: 100% 87%;
    }
    100% {
      background-position: 0% 14%;
    }
  }
  @-moz-keyframes BackgroundScroll {
    0% {
      background-position: 0% 14%;
    }
    50% {
      background-position: 100% 87%;
    }
    100% {
      background-position: 0% 14%;
    }
  }
  @keyframes BackgroundScroll {
    0% {
      background-position: 0% 14%;
    }
    50% {
      background-position: 100% 87%;
    }
    100% {
      background-position: 0% 14%;
    }
  } */
`;

export const MobileHomeContainer = styled.div`
  min-height: 100vh;
  min-width: 95vw;
  background-color: rgb(54, 57, 63);
`;
