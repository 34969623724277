import { Dispatch, SetStateAction} from "react";
import { ResumeItem } from "../../common/types";
import { ResumeItemContainer } from "./ResumeItems.styles";

type ResumeItemsProps = {
    resumeItems:ResumeItem[]|undefined;
    setActiveYears:Dispatch<SetStateAction<number[]>>;
}

const ResumeItems = (props:ResumeItemsProps) => {
    const {resumeItems, setActiveYears} = props;
    const currYear = new Date().getFullYear();
    function setActiveYearsHover(year1:number|string, year2:number|string|undefined){
        var yearN1 = year1;
        var yearN2 = year2;
        if(yearN1.toString().toLowerCase() === "current")
        {
            yearN1 = currYear;
        }
        if(typeof yearN2 === "undefined" || yearN2.toString().toLowerCase() === "current" )
        {
            yearN2 = currYear;
        }
        var yearNum1 = Number.parseInt(yearN1.toString())
        var yearNum2 = Number.parseInt(yearN2.toString())
        var yearArray = []
        if(yearNum2-yearNum1 > 0)
        {
            for(var i = yearNum1; i <= yearNum2; i++ )
            {   
                yearArray.push(i);
            }
        }
        else{
            yearArray.push(yearNum1);
        }
        setActiveYears(yearArray);
    }
    function removeActiveYears() {
        setActiveYears([])
    }
    return(
        <div>
            {resumeItems?.map((item)=> 
            {
                const end = item.endYear? item.endYear:"Current";
                return(
                    <ResumeItemContainer 
                    onMouseEnter={() => setActiveYearsHover(item.startYear,end)} 
                    onMouseLeave={() => removeActiveYears()}
                    onClick={()=> console.log(item.endYear? item.endYear :currYear )}
                    key={item.companyName} >
                        <p>{item.startYear}-{end}</p>
                        <p>{item.companyName}</p>
                        <p>{item.jobTitle}</p>
                    </ResumeItemContainer>
                );
            })}
        </div>
    );
}
export default ResumeItems;