import { ReactComponent as TSLogo } from "../../img/svg/typescript.svg";
import { ReactComponent as FirebaseLogo } from "../../img/svg/firebase.svg";
import { ReactComponent as ReactLogo } from "../../img/svg/react.svg";
import { FooterContainer} from "./StickyCornerFooter.styles";
import { useWindowDimensions } from "../../common/utils";

const StickyCornerFooter = () => {
  const { width } = useWindowDimensions();
  if(width < 780)
  {
    return null;
  }
  return (
    
      <FooterContainer>
        <ReactLogo height={"2.5rem"} width={"2.5rem"}/>
        <TSLogo height={"2.5rem"} width={"2.5rem"} />
        <FirebaseLogo height={"2.5rem"} width={"2.5rem"} />
        <div style={{color:"white"}}><p style={{margin:0, padding:0,}}>Built with</p><p style={{margin:0, padding:0, fontSize:"1.5rem"}}> Firebase</p></div>
      </FooterContainer>
  );
};
export default StickyCornerFooter;
