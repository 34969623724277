import {useState, useEffect, RefObject} from 'react';

function getWindowDimensions() {
    const {innerWidth: width, innerHeight:height} = window;
    return{
        width, height
    };
}
export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return windowDimensions;
}

//https://usehooks-typescript.com/react-hook/use-on-click-outside
type AnyEvent = MouseEvent | TouchEvent

export function useOnClickOutside<T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: (event: AnyEvent) => void,
): void {
  useEffect(() => {
    const listener = (event: AnyEvent) => {
      const el = ref?.current

      // Do nothing if clicking ref's element or descendent elements
      if (!el || el.contains(event.target as Node)) {
        return
      }

      handler(event)
    }

    document.addEventListener(`mousedown`, listener)
    document.addEventListener(`touchstart`, listener)

    return () => {
      document.removeEventListener(`mousedown`, listener)
      document.removeEventListener(`touchstart`, listener)
    }

    // Reload only if ref or handler changes
  }, [ref, handler])
}

export function getArrayofYears(start:number, end:number)
{
  var array:number[] = [];
  for(var i = end; i >= start; i--)
  { 
    array.push(i);
  }
  return array;
}
export function ReturnYearsAsNumber(year1:number|string, year2:number|string)
{
  var year = new Date().getFullYear();
  if(year1.toString().toLowerCase() === "current")
  {
      year1 = year;
  }
  if(year2.toString().toLowerCase() === "current")
  {
      year2 = year;
  }
  var yearNum1 = Number.parseInt(year1.toString())
  var yearNum2 = Number.parseInt(year2.toString())
  return {yearNum1, yearNum2};

}
export function setWithExpiry(key:string,value:any,ttl:number)
{
  const now = new Date();
  const valueString = JSON.stringify(value);
  const item= { 
    value:valueString,
    expiry:now.getTime() + ttl,
  }
  localStorage.setItem(key,JSON.stringify(item))
}
export function getWithExpiry(key:string)
{
  const itemStr = localStorage.getItem(key);
  
  if(!itemStr) {
    return null
  }
  const item = JSON.parse(itemStr)
  const now = new Date();
  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key)
    return null
  }
  return item.value;
}
