import styled from "styled-components";

export const ProjectItemContainer = styled.div`
  border: "1px solid black";
  background-color: rgba(100, 100, 100, 0.5);
  border-radius: 0.5rem;
  color: white;
  margin: 1rem;
`;
export const DisplayContainer = styled.div`
  display: "flex";
  flex-wrap: "wrap";
  align-items: center;
  svg {
    height: 3rem;
    width: 3rem;
    margin: 0 0.5rem;
  }
`;
