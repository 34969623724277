import {
  FirestoreDataConverter,
  WithFieldValue,
  DocumentData,
  QueryDocumentSnapshot,
  SnapshotOptions,
} from "@firebase/firestore";
import { ResumeItem } from "../common/types";

export const resumeItemConverter: FirestoreDataConverter<ResumeItem> = {
  toFirestore(item: WithFieldValue<ResumeItem>): DocumentData {
    return {
      companyName: item.companyName,
      startYear: item.startYear,
      endYear: item.endYear ? item.endYear : undefined,
      jobTitle: item.jobTitle,
    };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): ResumeItem {
    const data = snapshot.data(options)!;
    return {
      id: data.id,
      companyName: data.companyName,
      startYear: data.startYear,
      jobTitle: data.jobTitle,
      endYear: data.endYear,
    };
  },
};
