//#region Icons
import { ReactComponent as Js } from "../../../img/svg/js.svg";
import { ReactComponent as Ts } from "../../../img/svg/typescript.svg";
import { ReactComponent as Html } from "../../../img/svg/html.svg";
import { ReactComponent as Css } from "../../../img/svg/css.svg";
import { ReactComponent as CSharp } from "../../../img/svg/csharp.svg";
import { ReactComponent as Php } from "../../../img/svg/php-icon.svg";
import { ReactComponent as Java } from "../../../img/svg/java.svg";
import { ReactComponent as Python } from "../../../img/svg/python.svg";
import { ILanguage } from "../../../common/types";
import { DisplayContainer } from "../ProjectItem.styles";
//#endregion
type ITechIconDisplay = {
    icons:ILanguage[]|undefined;
}

const LanguageDisplay = (props:ITechIconDisplay) => {
    const { icons } = props;
    if (icons?.length === 0 || typeof icons === "undefined") {
      return null;
    }
    const set = new Set(icons);


return(
    <DisplayContainer>
        {set.has("csharp") ? <CSharp /> : null}
        {set.has("java") ? <Java /> : null}
        {set.has("python") ? <Python /> : null}
        {set.has("javascript") ? <Js /> : null}
        {set.has("typescript") ? <Ts /> : null}
        {set.has("html") ? <Html /> : null}
        {set.has("css") ? <Css /> : null}
        {set.has("php") ? <Php /> : null}
        {set.has("ruby") ? null : null}
    </DisplayContainer>
);
}
export default LanguageDisplay