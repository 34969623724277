import {
  HomePageContainer,
  HomePageHeaderContainer,
  AboutMeContainer,
  SkillsContainer,
  FlexContainer,
} from "./HomePage.styles";
import Typewriter from "typewriter-effect";
//#region Icons
import { ReactComponent as Ts } from "../../img/svg/typescript.svg";
import { ReactComponent as ReactLogo } from "../../img/svg/react.svg";
import { ReactComponent as Firebase } from "../../img/svg/firebase.svg";
//#endregion

const HomePage = () => {
  return (
    <HomePageContainer>
      <HomePageHeaderContainer>
        <Typewriter
          onInit={(typewriter) => {
            typewriter

              .typeString("I'm a full-stack debelop")
              .pauseFor(300)
              .deleteChars(5)
              .typeString("veloper")
              .pauseFor(3000)
              .typeString(" sometimes...")
              .pauseFor(1000)
              .start();
          }}
          options={{ loop: true }}
        />
      </HomePageHeaderContainer>
      <AboutMeContainer>
        <h3>Hi, my name is</h3>
        <p style={{fontSize:"clamp(3rem, 3vw, 5rem)"}}>
         Edvin Lin
        </p>
        <p>
          I enjoy learning new technologies and improving my skills
        </p>

        <div>
          <h2>Technologies</h2>
          <FlexContainer>
            <div>
              <p>Most used</p>
              <SkillsContainer>
                <Ts />
                <ReactLogo />
                <Firebase />
              </SkillsContainer>
            </div>
          </FlexContainer>
        </div>
      </AboutMeContainer>
    </HomePageContainer>
  );
};
export default HomePage;
