import { useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import { useWindowDimensions, useOnClickOutside } from "../../common/utils";
import {
  DesktopNavBar,
  MobileNavBar,
  NavItem,
  MobileNavList,
  MobileNavItem,
  HamburgerButton
} from "./NavBar.styles";
import { routes } from "../../common/navigation";
import { Theme } from "../../common/theme";
import HamburgerMenu from '../../img/tsx/hamburger-menu'
import HomeIcon from '../../img/tsx/home';

const NavBar = () => {
  const { width } = useWindowDimensions();
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const mobileMenuRef = useRef(null);
  const handleClickOutside = () => {
      setMobileNavOpen(false);
  }
  useOnClickOutside(mobileMenuRef, handleClickOutside);

  

  if (width < 780) {
    return (
      <>
        <MobileNavBar>
          <NavLink to="/"><HomeIcon color={Theme.iconColor}/></NavLink>
          {mobileNavOpen ? (
            null
          ) : (
            <HamburgerButton onClick={() => setMobileNavOpen(true)}>
              <HamburgerMenu color={Theme.iconColor} size={50}/>
            </HamburgerButton>
          )}
        </MobileNavBar>
        {mobileNavOpen ? (
          <MobileNavList ref={mobileMenuRef}>
            {routes.map((item, i) => {
              return (
                <MobileNavItem key={i} onClick={() => setMobileNavOpen(false)}>
                  <NavLink to={item.path}>{item.name}</NavLink>
                </MobileNavItem>
              );
            })}
          </MobileNavList>
        ) : null}
      </>
    );
  }
  return (
    <DesktopNavBar>
      {routes.map((item, i) => {
        return (
          <NavItem key={i}> 
            <NavLink
              to={item.path}
              activeStyle={{ fontWeight: "bold", color: "white" }}
              end
            >
              {item.name}
            </NavLink>
          </NavItem>
        );
      })}
    </DesktopNavBar>
  );
};
export default NavBar;
