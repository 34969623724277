//#region Icons
import { ReactComponent as Sql } from "../../../img/svg/sql.svg";
import { ReactComponent as ReactLogo } from "../../../img/svg/react.svg";
import { ReactComponent as Firebase } from "../../../img/svg/firebase.svg";
import { ReactComponent as Postgre } from "../../../img/svg/Postgresql_elephant.svg";
import { ReactComponent as DotNetFramework } from "../../../img/svg/NET_Logo.svg";
import { ReactComponent as Next } from "../../../img/svg/next-js.svg";
import { ITechIcon } from "../../../common/types";
import { DisplayContainer } from "../ProjectItem.styles";
//#endregion
type ITechIconDisplay = {
  icons?: ITechIcon[] | undefined;
};

const TechIconDisplay = (props: ITechIconDisplay) => {
  const { icons } = props;
  if (icons?.length === 0 || typeof icons === "undefined") {
    return null;
  }
  const set = new Set(icons);
  return (
    <DisplayContainer>
      {set.has("react") ? <ReactLogo /> : null}
      {set.has("firebase") ? <Firebase /> : null}
      {set.has("dotnet") ? <DotNetFramework /> : null}
      {set.has("mysql") ? <Sql /> : null}
      {set.has("postgres") ? <Postgre /> : null}
      {set.has("graphql") ? null : null}
      {set.has("redis") ? null : null}
      {set.has("nextjs") ? <Next/> : null}
      {set.has("nodejs") ? null : null}
      {set.has("express") ? null : null}
      {set.has("django") ? null : null}
      {set.has("spring") ? null : null}
      {set.has("mongodb") ? null : null}
    </DisplayContainer>
  );
};
export default TechIconDisplay;
