type HomeIconProps = {
  color?: string;
  size?: number;
};
const HomeIcon = (props: HomeIconProps) => {
    const size = props.size? props.size : 24;
    const viewBox = `0 0 ${size} ${size}`;
  return (
    <svg width={size} height={size} viewBox={viewBox} fill={props.color}>
      <path d="M3 10v11h6v-7h6v7h6v-11L12,3z" />
    </svg>
  );
};
export default HomeIcon;
