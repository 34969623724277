import styled from "styled-components"
import { GeneralFontSizes, Theme } from "../../common/theme"

export const DesktopNavBar = styled.div`
    height:100%;
    position: fixed;
    z-index:1;
    top:0;
    left:0;
    transition: 0.5s ease;
    overflow-x: hidden;
    display:flex;
    flex-direction: column; 
    width: clamp(10rem, 16vw, 15rem);
    padding-top: clamp(1rem, 5vh, 2rem);
    padding-left: 1rem;
    background-color: ${Theme.baseColor};
`
export const MobileNavBar = styled.div`
    position:sticky;
    top:0;
    height: 5vh;
    display: flex;
    justify-content: space-between;
    z-index: 10;

    align-items:center;
    padding:0.5rem 2.5rem;
    background-color: ${Theme.baseColor};
`

export const NavItem = styled.div`
    margin:1rem;
    color: ${Theme.menuFontColor};
    font-size:${GeneralFontSizes.menuFontSize};
    a:visited{
        color:inherit;
    }
    a:hover{
        color:white;
    }

`
export const MobileNavList = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
    margin-left:-0.6rem;
    margin-right:-0.6rem;
    transition: 0.5s ease;
    
`
export const MobileNavItem = styled.div`
    margin:1rem;
    width:100%;
    text-align:center;
    color:${Theme.menuFontColor};
    font-size:${GeneralFontSizes.menuFontSize};

`
export const HamburgerButton = styled.div`

`