import { Route, Routes } from "react-router-dom";
//Components
import HomePage from "../../pages/HomePage/HomePage";
import CoverLetter from "../../pages/CoverLetter/CoverLetter";
import Contact from "../../pages/Contact/Contact";
import Resume from "../../pages/Resume/Resume";
import Education from "../../pages/Education/Education";
import Projects from "../../pages/Projects/Projects";
//Other
import { useWindowDimensions } from "../../common/utils";
import { DesktopHomeContainer, MobileHomeContainer } from "./Router.styles";

const Router = () => {
  const { width } = useWindowDimensions();
  const routes = (
    <>
      <Route path="/" element={<HomePage />} />
      <Route path="/coverletter" element={<CoverLetter />} />
      <Route path="/contactme" element={<Contact />} />
      <Route path="/resume" element={<Resume />} />
      <Route path="/education" element={<Education />} />
      <Route path="/projects" element={<Projects />} />
    </>
  );
  if (width < 780) {
    return (
      <MobileHomeContainer>
        <Routes>{routes}</Routes>
      </MobileHomeContainer>
    );
  }
  return (
    <DesktopHomeContainer>
      <Routes>{routes}</Routes>
    </DesktopHomeContainer>
  );
};

export default Router;
