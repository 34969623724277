import styled from "styled-components";

export const FooterContainer = styled.div`
    display:flex;
    align-items:center;
    justify-content:end;
    position:fixed;
    bottom:0;
    right:0;
    margin:0.5rem;
    svg {
        padding: 0 0.25rem;
    }
`;
