import { ThemeColorProps } from "./types"

export const Theme:ThemeColorProps = {
    baseColor: "rgba(37,40,46,1)",
    iconColor: "rgba(100,103,106,1)",
    baseFontColor: "white",
    backgroundColor:"#A2ACBF",
    headerColor:"white",
    divFontColor:"white",
    pFontColor:"white",
    aFontColor:"white",
    menuFontColor: "rgba(160,163,166,1)", 

}

export const LightTheme:ThemeColorProps = {
    baseColor: "rgba(37,40,46,1)",
    iconColor: "rgba(100,103,106,1)",
    baseFontColor: "black",
    backgroundColor:"white",
    divFontColor:"black",
    pFontColor:"black",
    aFontColor:"black",
    menuFontColor: "rgba(160,163,166,1)", 

}
export const GeneralFontSizes = {
    menuFontSize: "1.5rem",
    defaultFontSize: "1.2rem",
}

export const lightTheme = {

}