import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "@firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDSaot1XpQzScn_Pjlg9JKuuW4QPU2pdgs",
  authDomain: "edvin-cra-portfolio2021.firebaseapp.com",
  projectId: "edvin-cra-portfolio2021",
  storageBucket: "edvin-cra-portfolio2021.appspot.com",
  messagingSenderId: "371652757209",
  appId: "1:371652757209:web:59a86b30b724f84150a0c8",
  measurementId: "G-WNCQ2S0SY6",
};
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export const db = getFirestore(app);
