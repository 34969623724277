import { ResumeItem } from "../common/types";
import { getDocs, query, orderBy } from "@firebase/firestore";
import { resumeItemsCollectionRef } from "./collectionref";

export const getResumeItems = async () => {
  const q = query(resumeItemsCollectionRef, orderBy("startYear", "desc"));
  const data = await getDocs<ResumeItem>(q);

  const dataArray = data.docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
  }));
  return dataArray;
};
