import styled from "styled-components";
import { GeneralFontSizes } from "../../common/theme";

export const ResumeContainer = styled.div`
    padding-top:1rem;
    width:99.5%;
    margin:auto;
    display:flex;
`
export const ResumeItemContainer = styled.div`
    color:${props=>props.color?props.color:"white"};
    font-size:${GeneralFontSizes.defaultFontSize};
    height:auto;
    padding-top:1rem;
`
export const ResumeTimeLineContainer = styled.div`
    color:${props=>props.color?props.color:"white"};
    padding-right:4rem; 
    font-size:${GeneralFontSizes.defaultFontSize};

`
