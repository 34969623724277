import { useState, useEffect } from "react";
import ResumeItems from "../../components/ResumeItem/ResumeItems";
import ResumeTimeLine from "../../components/ResumeItem/ResumeTimeline";
import { ResumeItem } from "../../common/types";
import {
  ResumeContainer,
  ResumeItemContainer,
  ResumeTimeLineContainer,
} from "./Resume.styles";
import { getResumeItems } from "../../db/calls";
import { Constants } from "../../common/constants";
import { setWithExpiry, getWithExpiry } from "../../common/utils";
import { useTheme } from "../../common/context";

const Resume = () => {
  const [activeYears, setActiveYears] = useState<number[]>([]);
  const [displayedItems, setDisplayedItems] = useState<ResumeItem[]>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const {theme} = useTheme()
  const setResumeItems = async () => {
    var existingData:ResumeItem[] = JSON.parse(getWithExpiry(Constants.RESUME_LOCAL_STORAGE_ITEM_NAME));
    if(existingData)
    {
      setLoading(true);
      setDisplayedItems(existingData);
      setLoading(false);
    }
    else{
      setLoading(true);
      const dataArray = await getResumeItems();
      setDisplayedItems(dataArray);
      setWithExpiry(Constants.RESUME_LOCAL_STORAGE_ITEM_NAME, dataArray,3600000);
      setLoading(false);
    }
    
    
  }
  useEffect(() => {
    setResumeItems();
  }, []);

  if (isLoading) {
    return <div style={{display:"flex", height:"100%", width:"100%", fontSize:"5rem",justifyContent:"center",alignItems:"center" }}>LOADING</div>;
  }

  return (
    <ResumeContainer>
      <ResumeTimeLineContainer color={theme?.baseFontColor}>
        <ResumeTimeLine low={2016} activeYears={activeYears} />
      </ResumeTimeLineContainer>
      <ResumeItemContainer color={theme?.baseFontColor}>
        <ResumeItems
          resumeItems={displayedItems}
          setActiveYears={setActiveYears}
        />
      </ResumeItemContainer>
    </ResumeContainer>
  );
};
export default Resume;
